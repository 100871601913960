import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  Drawer,
  FormControl,
  IconButton,
  InputBase,
  styled,
} from "@mui/material";
import Container from "@mui/material/Container";
import clsx from "clsx";
import { FlexBox } from "~/components/flex-box";
import { Close } from "@mui/icons-material";
import { Link, useFetcher, useNavigate } from "@remix-run/react";
import { useEffect, useState, type FC } from "react";
import { Helmet } from "react-helmet";
import BazaarImage from "~/components/BazaarImage";
import NavLink from "~/components/nav-link/NavLink";
import { SearchOutlinedIcon } from "~/components/search-box/SearchBox";
import { Category } from "~/models";
import { useRootLoader } from "~/utils/use-root-loader";
import { useDebounce } from "~/utils/useDebounce";
import { Span } from "../Typography";
import MobileCategoryMenuList from "../categories/MobileCategoryMenuList";
import ChLeft from "../icons/ChLeft";

// styled component
export const HeaderWrapper = styled(Box)(({ theme }) => ({
  zIndex: 3,
  position: "relative",
  // height: layoutConstant.headerHeight,
  transition: "height 250ms ease-in-out",
  background: theme.palette.background.paper,
  // background: `url("/assets/images/WMoon/PhoneHead.png")`,
  backgroundSize: "cover",
  marginTop: "-1px",
  [theme.breakpoints.down("md")]: {
    // height: layoutConstant.mobileHeaderHeight,
    background: "#2d68c4",
  },
  "&.MuiAccordionSummary-content": {
    margin: 0,
  },
}));

export const StyledDiv = styled(Box)<{ open: any }>(({ open }) => ({
  position: "absolute",
  /* transform: translateY(var(--visible-aside)); */
  backgroundColor: "white",
  // zIndex: "-1",
  overflow: "scroll",
  boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
  height: "calc(100vh - 52px - 64px)",
  minHeight: 0,
  maxHeight: "none",
  width: "100%",
  border: "none",
  transition: "transform 500ms cubic-bezier(0.4, 0, 0.2, 1)",
  transform: open ? "translateX(0%)" : "translateX(-100%)",
  left: "-0.75rem",
  top: "calc(100%)",
}));

// ==============================================================
type MobileHeaderProps = {
  className?: string;
};
// ==============================================================

const MobileHeader: FC<MobileHeaderProps> = ({ className }) => {
  const { categories } = useRootLoader();

  let [query, setQuery] = useState("q");
  let [debouncedQuery] = useDebounce(query, 500);
  const [openHelp, setOpenHelp] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [open, setOpen] = useState(false);
  const [searchClicked, setSearchClicked] = useState(false);
  const fetcher = useFetcher();
  const navigate = useNavigate();

  const handleOnSubmit = (e) => {
    e.preventDefault();
    navigate(`/s?q=${query}`);
    if (document.activeElement instanceof HTMLElement) {
      setTimeout(function () {
        document.activeElement instanceof HTMLElement &&
          document.activeElement.blur();
      }, 500);
    }
  };

  useEffect(() => {
    const detector = () =>
      window.innerWidth <= 1024 ? setIsMobile(true) : setIsMobile(false);

    //before attaching event listener run once
    detector();
    if (!window) return;
    //then attach a event listener to listen for resize
    window.addEventListener("resize", detector);
    //clean event listener
    return () => {
      window.removeEventListener("resize", detector);
    };
  }, []);
  const handleClick = () => setOpen((prev) => !prev);

  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
      </Helmet>
      <HeaderWrapper className={clsx(className)}>
        <Container
          sx={{
            gap: 1,
            height: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            // pr: "40px!important",
          }}
        >
          <Dialog
            open={openHelp}
            onClose={() => setOpenHelp(false)}
            maxWidth="xs"
          >
            <DialogTitle
              textAlign={"center"}
              sx={{ py: 1, borderBottom: "1px solid #00000080" }}
            >
              Тусламж
              <IconButton
                aria-label="close"
                onClick={() => setOpenHelp(false)}
                sx={{
                  position: "absolute",
                  right: 2,
                  top: 2,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <Close />
              </IconButton>
            </DialogTitle>
            <DialogContent sx={{ p: 0 }}>
              <Box
                px={4}
                py={1}
                textAlign={"center"}
                sx={{ borderBottom: "1px solid #00000080" }}
              >
                <NavLink
                  className="link"
                  to="/help"
                  onClick={() => setOpenHelp(false)}
                >
                  Захиалга хийх заавар
                </NavLink>
              </Box>
              <Box
                px={4}
                py={1}
                textAlign={"center"}
                sx={{ borderBottom: "1px solid #00000080" }}
              >
                <NavLink
                  className="link"
                  to="/tos"
                  onClick={() => setOpenHelp(false)}
                >
                  Үйлчилгээний нөхцөл
                </NavLink>
              </Box>
              <Box
                px={4}
                py={1}
                textAlign={"center"}
                sx={{ borderBottom: "1px solid #00000080" }}
              >
                <NavLink
                  className="title"
                  to="/ot"
                  onClick={() => setOpenHelp(false)}
                >
                  Захиалгын төлөв шалгах
                </NavLink>
              </Box>
              <Box px={4} py={1} textAlign={"center"}>
                <NavLink
                  className="title"
                  to="tel:18002888"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <img
                    src={"/assets/images/icons/Support-black.svg"}
                    width="16px"
                    height={"16px"}
                    style={{ marginRight: "5px" }}
                  />
                  1800-2888
                </NavLink>
              </Box>
            </DialogContent>
          </Dialog>
          {/* <StyledDiv open={open}> */}
          {/* <div style={{ height: "100%", userSelect: "none" }}> */}
          <Drawer
            open={open}
            onClose={() => setOpen(false)}
            onClick={(e) => {
              e.stopPropagation();
            }}
            PaperProps={{
              style: {
                maxHeight: "calc(100% - 52px )",
                // height: "100%",
                // top: 52,
                width: "100%",
                position: "relative",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                mr: 2.5,
                mt: 1,
              }}
            >
              <ChLeft onClick={handleClick} sx={{ width: "50px" }} />
              <Box ml={"-10px"} mt={"1px"} onClick={handleClick}>
                Хаах
              </Box>
            </Box>
            <Box pb={2}>
              {categories.map(
                (category: Category, index) =>
                  category.isIncludedInMenu && (
                    <MobileCategoryMenuList
                      category={category}
                      key={index}
                      close={() => setOpen(false)}
                    />
                  )
              )}
            </Box>
          </Drawer>
          {/* </div> */}
          {/* {categories.map((category, index) => (
            <MobileCategoryTreeBranch
              hideTree={() => setOpen(false)}
              name={category.name}
              url={category.url}
              children={category.children}
            />
          ))}
        </StyledDiv> */}
          {/* <Drawer
          anchor="left"
          open={open}
          onClose={() => setOpen(false)}
          SlideProps={{
            style: {
              width: "100%",
              height: "calc(100vh - 52px - 64px)",
              top: 52,
            },
          }}
        >
          <Toolbar sx={{ minHeight: !searchClicked ? 52 : 105 }} />
          lalala
        </Drawer> */}
          {isMobile && (
            <FlexBox width={"100%"} pt={1}>
              <FlexBox
                sx={{
                  // position: "fixed",
                  // left: "1.25rem",
                  // top: "0.75rem",
                  justifyContent: "flex-start",
                  flex: 1,
                  alignItems: "center",
                  overflow: "hidden",
                  height: "2rem",
                  width: "2rem",
                }}
              >
                <Span
                  sx={{
                    width: "fit-content",
                    height: "fit-content",
                    position: "absolute",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={handleClick}
                >
                  <BurgerIcon isMobile={true} />
                </Span>
              </FlexBox>
              <FlexBox sx={{ flex: 1, justifyContent: "center" }}>
                <Link to="/">
                  <BazaarImage
                    height={30}
                    width={110}
                    src="/assets/images/logos/Nomin-logo-white.svg"
                    alt="logo"
                  />
                </Link>
              </FlexBox>
              <FlexBox sx={{ flex: 1, justifyContent: "flex-end" }}>
                <FlexBox
                  width={"2rem"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  mr={1}
                >
                  <Button onClick={() => setSearchClicked(!searchClicked)}>
                    <SearchRoundedIcon style={{ color: "white" }} />
                  </Button>
                </FlexBox>
                <FlexBox
                  width={"2rem"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <HelpOutlineRoundedIcon
                    style={{ color: "white" }}
                    onClick={() => setOpenHelp(true)}
                  />
                </FlexBox>
              </FlexBox>
            </FlexBox>
          )}
          <Collapse in={searchClicked} sx={{ width: "100%" }}>
            <Box
              width="100%"
              sx={{ background: "#FFF", mb: 1, borderRadius: "10px" }}
            >
              <fetcher.Form action="/s" method="get" onSubmit={handleOnSubmit}>
                <input type="hidden" name="q" value={debouncedQuery} />
                <FormControl id="search-form" fullWidth variant="outlined">
                  <InputBase
                    sx={{ m: 1, flex: 1 }}
                    placeholder="Хайх"
                    onChange={(e) => setQuery(e.target.value)}
                    // onSubmit={(e) => navigate(`/s?q=${debouncedQuery}`) }
                    autoFocus={true}
                    // defaultValue={defaultValue}
                    inputProps={{
                      sx: {
                        paddingRight: 0,
                        borderRadius: 300,
                        color: "black.50",
                        overflow: "hidden",
                      },
                    }}
                    startAdornment={<SearchOutlinedIcon fontSize="small" />}
                  />
                </FormControl>
              </fetcher.Form>
            </Box>
          </Collapse>

          {/* {downMd && <MobileMenu />} */}
        </Container>
      </HeaderWrapper>
    </>
  );
};

export default MobileHeader;

export const BurgerIcon = ({ isMobile, isActive = false, ...props }) => (
  <svg
    width={30}
    height={15}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      width={30}
      height={3}
      rx={1}
      fill={isMobile || isActive ? "white" : "#FF2C2C"}
    />
    <rect
      width={30}
      height={3}
      y={6}
      rx={1}
      fill={isMobile || isActive ? "white" : "#FF2C2C"}
    />
    <rect
      width={30}
      height={3}
      y={12}
      rx={1}
      fill={isMobile || isActive ? "white" : "#FF2C2C"}
    />
  </svg>
);

export const XIcon = () => {
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <line x1="18" y1="6" x2="6" y2="18"></line>
    <line x1="6" y1="6" x2="18" y2="18"></line>
  </svg>;
};
