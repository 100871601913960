import { AppBar, LinearProgress } from "@mui/material";
import { useNavigation } from "@remix-run/react";
import { FC, ReactNode } from "react";
import { Fragment, useCallback, useState } from "react";
import Footer from "~/components/footer/Footer";
import Topbar from "~/components/topbar/Topbar";
/**
 *  Used in:
 *  1. market-1, matket-2, gadget-shop,
 *     fashion-shop, fashion-shop-2, fashion-shop-3, furniture-shop, grocery3, gift-shop
 *  2. product details page
 *  3. order-confirmation page
 *  4. product-search page
 *  5. shops and shops-details page
 *  6. checkoutNavLayout and CustomerDashboadLayout component
 */

// ===================================================
type ErrorLayoutProps = {
  children?: ReactNode;
  showTopbar?: boolean;
  showNavbar?: boolean;
  topbarBgColor: string;
};
// ===================================================

const ErrorLayout: FC<ErrorLayoutProps> = ({
  children,
  showTopbar = true,
  topbarBgColor,
}) => {
  const [isFixed, setIsFixed] = useState(false);
  const toggleIsFixed = useCallback((fixed: boolean) => setIsFixed(fixed), []);
  const navigation = useNavigation();
  const showLoading = navigation.state !== "idle";
  return (
    <Fragment>
      {showLoading && <LinearProgress color="primary" sx={{ height: 4 }} />}

      {/* TOPBAR */}
      {/* {showTopbar && (
        <Topbar bgColor={topbarBgColor} showLoading={showLoading} />
      )} */}

      {/* HEADER */}

      <div className="section-after-sticky">
        {/* NAVIGATION BAR */}
        {/* {showNavbar && <Navbar elevation={0} border={1} />} */}

        {/* BODY CONTENT */}
        {children}
      </div>

      <Footer key="main-footer" />
    </Fragment>
  );
};

export default ErrorLayout;
