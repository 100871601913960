import { Badge, Box, Drawer, styled } from "@mui/material";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import { Link, useNavigation, useRevalidator } from "@remix-run/react";
import clsx from "clsx";
import { useEffect, useState, type FC } from "react";
import BazaarImage from "~/components/BazaarImage";
import FlashDeliveryButton from "~/components/flash-delivery/FlashDeliveryButton";
import { FlexBox } from "~/components/flex-box";
import MiniCart from "~/components/mini-cart/MiniCart";
import SearchBox from "~/components/search-box/SearchBox";
import { layoutConstant } from "~/utils/constants";
import { useRootLoader } from "~/utils/use-root-loader";
import AccountPopover from "../AccountPopover";
import NavbarIcon from "../navbar/NavbarIcon";
import CartEmpty from "./CartEmpty";
import CartFilled from "./CartFilled";
import Notifications from "./Notifications";

// styled component
export const HeaderWrapper = styled(Box)(({ theme }) => ({
  zIndex: 3,
  position: "relative",
  height: layoutConstant.headerHeight,
  transition: "height 250ms ease-in-out",
  background: theme.palette.background.paper,
  [theme.breakpoints.down("md")]: {
    height: 52,
    background: "#2d68c4",
    opacity: 0,
  },
}));
export const LogoBox = styled(Box)(({ theme }) => ({
  position: "relative",
  maxWidth: "100%",
  "& .image-hover": {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    objectFit: "contain",
    opacity: 0,
    transition: "opacity .2s",
  },
  "&:hover .image-hover": {
    opacity: 1,
  },
  "&:hover .image-main": {
    opacity: 0,
  },
}));

// ==============================================================
type HeaderProps = {
  isFixed?: boolean;
  className?: string;
};
// ==============================================================

const Header: FC<HeaderProps> = ({ className }) => {
  const { cart, isCustomerLoggedIn, notifications } = useRootLoader();

  const [sidenavOpen, setSidenavOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [scrollY, setScrollY] = useState(0);

  const toggleSidenav = () => setSidenavOpen(!sidenavOpen);

  const navigation = useNavigation();
  let revalidator = useRevalidator();

  useEffect(() => {
    navigation.formData?.get("_action") === "add-to-cart"
      ? setSidenavOpen(true)
      : null;
    revalidator.revalidate();
  }, [navigation]);

  useEffect(() => {
    if (typeof window != undefined) {
      const handleScroll = (event) => {
        setScrollY(window.scrollY);
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  useEffect(() => {
    const detector = () =>
      window.innerWidth <= 1024 ? setIsMobile(true) : setIsMobile(false);

    //before attaching event listener run once
    detector();
    if (!window) return;
    //then attach a event listener to listen for resize
    window.addEventListener("resize", detector);
    //clean event listener
    return () => {
      window.removeEventListener("resize", detector);
    };
  }, []);

  return (
    <HeaderWrapper className={clsx(className)}>
      <Container
        sx={{
          gap: 2,
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <FlexBox
          mr={2}
          minWidth="170px"
          alignItems="center"
          sx={{ display: { xs: "none", md: "flex" } }}
        >
          <Link to="/">
            <BazaarImage
              height={40}
              src="/assets/images/logos/Nomin-logo-mn.svg"
              alt="logo"
            />
          </Link>
          {scrollY >= 110 ? <NavbarIcon /> : null}
        </FlexBox>

        <FlexBox justifyContent="center">
          <FlashDeliveryButton />
        </FlexBox>

        <FlexBox justifyContent="center" flex="1 1 0">
          {<SearchBox />}
        </FlexBox>

        {isMobile && <FlexBox></FlexBox>}

        <FlexBox
          alignItems="center"
          sx={{ display: { xs: "none", md: "flex" } }}
        >
          {/* Notifications */}
          <Notifications isMobile={false} notifications={notifications} />

          {isCustomerLoggedIn ? (
            <AccountPopover isMobile={false} />
          ) : (
            <Link to="/login">
              <Box component={IconButton} p={1.25} bgcolor="grey.200">
                {/* <Profile /> */}
                <Box width={"24px"} height="24px">
                  <img
                    src="/assets/images/icons/Profile.svg"
                    width="100%"
                    height="100%"
                  />
                </Box>
              </Box>
            </Link>
          )}

          <Badge
            id="cart-items-count"
            badgeContent={cart.itemsCount}
            color="primary"
          >
            <Box
              ml={2.5}
              p={1.25}
              bgcolor="grey.200"
              color="grey.200"
              component={IconButton}
              onClick={toggleSidenav}
            >
              {cart.itemsCount == 0 ? <CartEmpty /> : <CartFilled />}
            </Box>
          </Badge>
        </FlexBox>

        <Drawer
          open={sidenavOpen}
          anchor="right"
          onClose={toggleSidenav}
          id="cart"
        >
          <MiniCart toggleSidenav={toggleSidenav} cart={cart} />
        </Drawer>

        {/* {downMd && <MobileMenu />} */}
      </Container>
    </HeaderWrapper>
  );
};

export default Header;

export const BurgerIcon = ({ isMobile, isActive = false, ...props }) => (
  <svg
    width={20}
    height={10}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      width={20}
      height={2}
      rx={1}
      fill={isMobile || isActive ? "white" : "#FF2C2C"}
    />
    <rect
      width={20}
      height={2}
      y={4}
      rx={1}
      fill={isMobile || isActive ? "white" : "#FF2C2C"}
    />
    <rect
      width={20}
      height={2}
      y={8}
      rx={1}
      fill={isMobile || isActive ? "white" : "#FF2C2C"}
    />
  </svg>
);

export const XIcon = () => {
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <line x1="18" y1="6" x2="6" y2="18"></line>
    <line x1="6" y1="6" x2="18" y2="18"></line>
  </svg>;
};
