import { ChevronRight } from "@mui/icons-material";
import { Box, MenuItem, styled } from "@mui/material";
import { Link } from "@remix-run/react";
import type { FC, ReactElement } from "react";
import BazaarImage from "../BazaarImage";

//styled component
const Wrapper = styled(Box)(({ theme }) => ({
  "& .category-dropdown-link": {
    height: 40,
    display: "flex",
    minWidth: "278px",
    cursor: "pointer",
    whiteSpace: "pre",
    padding: "0px 1rem",
    alignItems: "center",
    transition: "all 250ms ease-in-out",
    "& .title": { flexGrow: 1, paddingLeft: "0.75rem", fontWeight:"400" },
  },
  "&:hover": {
    "& > .category-dropdown-link": {
      color: theme.palette.primary.main,
      background: theme.palette.action.hover,
    },
    "& > .mega-menu": { display: "block",  },
  },
}));

// =============================================================
type CategoryMenuItemProps = {
  children?: ReactElement | ReactElement[];
  icon?: any;
  url: string;
  title: string;
  caret?: boolean;
};
// =============================================================

const CategoryMenuItem: FC<CategoryMenuItemProps> = (props) => {
  const { url, title, caret, children, icon, ...rest } = props;
  return (
    <Wrapper>
      <Link to={url}>
        <MenuItem className="category-dropdown-link">
          {<BazaarImage src={icon} sx={{ width: 20, height: 20 }} />}
          <span className="title">{title}</span>
          {caret && <ChevronRight fontSize="small" />}
        </MenuItem>
      </Link>

      {children}
    </Wrapper>
  );
};

CategoryMenuItem.defaultProps = { caret: true };

export default CategoryMenuItem;
