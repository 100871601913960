import { ExpandMore, ArrowRightAlt } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  MenuItem,
  styled,
} from "@mui/material";
import { Link } from "@remix-run/react";
import { FC, Fragment, ReactElement } from "react";
import BazaarImage from "../BazaarImage";
import MobileCategoryMenuItem from "./MobileCategoryMenuItem";

//styled component
const Wrapper = styled(Box)(({ theme }) => ({
  "& .MuiAccordionSummary-content": {
    margin: 0,
  },
  "& .MuiAccordionSummary-root": {
    paddingLeft: 0,
  },
}));

// =============================================================
type MobileCategoryMenuListProps = {
  category: any;
  close: () => void;
};
// =============================================================

const MobileCategoryMenuList: FC<MobileCategoryMenuListProps> = (props) => {
  const { category, close } = props;
  return (
    <Wrapper>
      <Accordion disableGutters>
        <AccordionSummary
          expandIcon={
            category.caret && (
              <ExpandMore fontSize="small" className="dropdown-icon" />
            )
          }
        >
          <MobileCategoryMenuItem
            url={category.url}
            icon={category.icon}
            title={category.name}
            caret={category.caret}
          />
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>
          {category.children &&
            category.children.map((subcategory, index) => (
              <Fragment key={index}>
                <Accordion disableGutters>
                  <AccordionSummary
                    expandIcon={
                      subcategory.caret && (
                        <ExpandMore
                          fontSize="small"
                          className="dropdown-icon"
                        />
                      )
                    }
                    sx={{ pl: 2.5 }}
                  >
                    <Box sx={{ pl: 2.5, width: "100%" }}>
                      <MobileCategoryMenuItem
                        url={subcategory.url}
                        icon={subcategory.icon}
                        title={subcategory.name}
                        caret={subcategory.caret}
                        close={close}
                      />
                    </Box>
                  </AccordionSummary>
                  {subcategory.children &&
                    subcategory.children.length !== 0 && (
                      <AccordionDetails sx={{ p: 0 }}>
                        {subcategory.children &&
                          subcategory.children.map((subSubcategory, index) => (
                            <Box
                              sx={{ pl: 5, width: "100%" }}
                              key={index}
                              borderBottom={"1px solid #F3F5F9"}
                            >
                              <MobileCategoryMenuItem
                                url={subSubcategory.url}
                                icon={subSubcategory.icon}
                                title={subSubcategory.name}
                                caret={subSubcategory.caret}
                                close={close}
                                clickable={true}
                              />
                            </Box>
                          ))}
                        <Link to={subcategory.url} onClick={close}>
                          <MenuItem
                            sx={{
                              color: "#2d68c4",
                              pl: 8.5,
                              display: "flex",
                              alignItems: "center",
                              minHeight: "48px!important",
                            }}
                          >
                            <span className="title">{`${subcategory.name} бүгдийг үзэх`}</span>
                            <ArrowRightAlt />
                          </MenuItem>
                        </Link>
                      </AccordionDetails>
                    )}
                </Accordion>
              </Fragment>
            ))}
          <Link to={category.url} onClick={close}>
            <MenuItem
              sx={{
                color: "#2d68c4",
                pl: 6,
                display: "flex",
                alignItems: "center",
                minHeight: "48px!important",
              }}
            >
              <span className="title">{`${category.name} бүгдийг үзэх`}</span>
              <ArrowRightAlt />
            </MenuItem>
          </Link>
        </AccordionDetails>
      </Accordion>
    </Wrapper>
  );
};

export default MobileCategoryMenuList;
