import { CallOutlined, LocalShippingOutlined } from "@mui/icons-material";
import { Box, Container, styled } from "@mui/material";
import { Link } from "@remix-run/react";
import { FC } from "react";
import { FlexBox } from "~/components/flex-box";
import NavLink from "~/components/nav-link/NavLink";
import { Span } from "~/components/Typography";
import { layoutConstant } from "~/utils/constants";
import appIcons from "../icons";
import TopBarPhone from "~/components/icons/TopBarPhone";

const TopbarWrapper = styled(Box)<{ bgcolor: any }>(({ theme, bgcolor }) => ({
  fontSize: 13,
  height: layoutConstant.topbarHeight,
  background: bgcolor || "#2d68c4",
  color: theme.palette.secondary.contrastText,
  [theme.breakpoints.down("md")]: { display: "none !important" },
  "& .topbarLeft": {
    "& .logo": { display: "none" },
    "& .title": { marginLeft: "8px" },
    "@media only screen and (max-width: 1024px)": {
      "& .logo": { display: "block" },
      "& > *:not(.logo)": { display: "none" },
    },
  },
  "Top-bar-phone": {
    marginTop: "10px",
    paddingTop: "2px",
  },
  "& .topbarRight": {
    "& .link": {
      paddingLeft: 20,
      color: theme.palette.secondary.contrastText,
    },

    "@media only screen and (max-width: 1024px)": {
      "& .link": { display: "none" },
    },
  },
  "& .menuItem": { minWidth: 100 },
  "& .marginRight": { marginRight: "1.25rem" },
  "& .handler": { height: layoutConstant.topbarHeight },
  "& .smallRoundedImage": { height: 15, width: 25, borderRadius: 2 },
  "& .menuTitle": { fontSize: 13, marginLeft: "0.5rem", fontWeight: 600 },
}));

type TopbarProps = {
  bgColor: string;
  showLoading: boolean;
};

const Topbar: FC<TopbarProps> = ({ bgColor, showLoading }) => {
  const NominLogo = appIcons["NominLogo"];
  return (
    <TopbarWrapper
      bgcolor={bgColor}
      // sx={{ display: { xs: "none", md: "flex" } }}
    >
      <Container
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <FlexBox className="topbarLeft" alignItems="center">
          <div className="logo">
            <Link to="/">
              <NominLogo />
              {/* <Image display="block" height="28px" src="/assets/images/logo.svg" alt="logo" /> */}
            </Link>
          </div>

          <Span className="title">Хүний хэрэгцээ цөм багтсан</Span>
          {/* <FlexBox alignItems="center">
            <CallOutlined fontSize="small" />
            <Span className="title">1800-2888</Span>
          </FlexBox> */}
        </FlexBox>

        <FlexBox className="topbarRight" alignItems="left">
          <Link to="tel:18002888">
            <FlexBox>
              {/* <TopBarPhone className="Top-bar-phone" style={{paddingTop:"5px", }}/> */}
              <img
                src={"/assets/images/icons/Support.svg"}
                width="16px"
                height={"16px"}
                style={{ marginRight: "5px", paddingTop: "2px" }}
              />
              <Span className="title"> 1800-2888</Span>
            </FlexBox>
          </Link>
          {/* <Link to="/c/gas" style={{ paddingLeft: 30 }}>
            Газ
          </Link> */}

          <FlexBox paddingLeft={3.75}>
            <NavLink className="title" to="/ot">
              Захиалга шалгах
            </NavLink>
          </FlexBox>

          <NavLink className="link" to="/tos">
            Үйлчилгээний нөхцөл
          </NavLink>

          <NavLink className="link" to="/help">
            Тусламж
          </NavLink>
        </FlexBox>
      </Container>
    </TopbarWrapper>
  );
};

export default Topbar;
