import { ChevronRight, KeyboardArrowDown } from "@mui/icons-material";
import ArrowRight from "@mui/icons-material/ArrowRight";
import {
  Box,
  Container,
  MenuItem,
  styled,
  type SvgIconProps,
} from "@mui/material";
import BazaarButton from "~/components/BazaarButton";
import BazaarCard from "~/components/BazaarCard";
import CategoryMenu from "~/components/categories/CategoryMenu";
import { FlexBox } from "~/components/flex-box";
import appIcons from "~/components/icons";
import NavLink from "~/components/nav-link/NavLink";
import { Paragraph, Span } from "~/components/Typography";
import navbarNavigations from "~/data/navbarNavigations";
import type { FC } from "react";
import MegaMenu from "./MegaMenu";
import MegaMenu2 from "./MegaMenu2";
import { Link } from "@remix-run/react";

// NavList props interface
type Navs = {
  url: string;
  title: string;
  Icon?: (props: SvgIconProps<"svg", {}>) => JSX.Element;
};

type NavList = {
  url: string;
  title: string;
  child: Navs[];
  megaMenu: boolean;
  megaMenuWithSub: boolean;
};

// const common css style
const navLinkStyle = {
  cursor: "pointer",
  transition: "color 150ms ease-in-out",
  "&:hover": { color: "primary.main" },
  "&:last-child": { marginRight: 0 },
};
// style components
const StyledNavLink = styled(NavLink)(() => ({ ...navLinkStyle }));

const ParentNav = styled(Box)(({ theme }) => ({
  "&:hover": {
    color: theme.palette.primary.main,
    "& > .parent-nav-item": { display: "block" },
  },
}));

const ParentNavItem = styled(Box)(({ theme }) => ({
  top: 0,
  zIndex: 5,
  left: "100%",
  //   paddingLeft: 8,
  display: "none",
  position: "absolute",
  [theme.breakpoints.down(1640)]: {
    right: "100%",
    left: "auto",
    // paddingRight: 8,
  },
}));

const NavBarWrapper = styled(BazaarCard)<{ border: number }>(
  ({ theme, border }) => ({
    // height: "60px",
    display: "block",
    borderRadius: "0px",
    position: "relative",
    boxShadow: "none",
    width: "40px",
    marginRight: "15px",
  })
);

const InnerContainer = styled(Container)(() => ({
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const CategoryMenuButton = styled(BazaarButton)(({ theme }) => ({
  //   width: "50px",
  //   height: "40px",
  //   backgroundColor: theme.palette.grey[100],
}));

const ChildNavsWrapper = styled(Box)(() => ({
  zIndex: 5,
  left: "50%",
  top: "100%",
  display: "none",
  position: "absolute",
  transform: "translate(-50%, 0%)",
}));

// ==========================================================
type NavbarIconProps = {
  elevation?: number;
  navListOpen?: boolean;
  hideCategories?: boolean;
};
// ==========================================================

const NavbarIcon: FC<NavbarIconProps> = ({
  navListOpen,
  hideCategories,
  elevation,
}) => {
  const renderNestedNav = (list: any[] = [], isRoot = false) => {
    return list.map((nav: NavList) => {
      if (isRoot) {
        return (
          <Link to={nav.url} key={nav.title}>
            <Span
              sx={{
                gap: 1,
                lineHeight: 1,
                alignItems: "center",
                position: "relative",
                paddingBottom: "4px",
                display: "inline-flex",
                fontWeight: "400",
                ":after": {
                  left: 0,
                  bottom: 0,
                  width: "0%",
                  content: "''",
                  height: "2px",
                  transition: "0.3s",
                  position: "absolute",
                },
                ":hover": {
                  color: "primary.main",
                  "&::after": {
                    width: "100%",
                    backgroundColor: "primary.main",
                  },
                },
              }}
            >
              {/* <MenuItem sx={{ px: 0, ml: 2 }}> */}
              {nav.title}
              {/* </MenuItem> */}
            </Span>
          </Link>
        );
      }
    });
  };

  const Category = appIcons["Category"];

  return (
    <NavBarWrapper
      hoverEffect={false}
      elevation={elevation}
      border={0}
      sx={{ background: "transparent" }}
    >
      {!hideCategories ? (
        <InnerContainer>
          {/* Category megamenu */}
          <CategoryMenu open={navListOpen}>
            <CategoryMenuButton variant="text">
              <Category fontSize="small" fontWeight="400" />

              <ChevronRight className="dropdown-icon" fontSize="small" />
            </CategoryMenuButton>
          </CategoryMenu>
        </InnerContainer>
      ) : null}
    </NavBarWrapper>
  );
};

//  set default props data
NavbarIcon.defaultProps = {
  elevation: 2,
  navListOpen: false,
  hideCategories: false,
};

export default NavbarIcon;
