import {
  Avatar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  styled,
} from "@mui/material";
import { Link } from "@remix-run/react";
import React, { FC, useRef, useState } from "react";
import { useRootLoader } from "~/utils/use-root-loader";
import { H6, Small } from "./Typography";
import { Block } from "@mui/icons-material";

// styled components
const Divider = styled(Box)(({ theme }) => ({
  margin: "0",
  border: `1px dashed ${theme.palette.grey[200]}`,
}));

type AccountPopoverProps = {
  isMobile: boolean;
};

const AccountPopover: FC<AccountPopoverProps> = ({ isMobile }) => {
  const { profile } = useRootLoader();
  const [anchorEl, setAnchorEl] = useState(null);
  const [avatar, setAvatar] = useState(profile["avatarUrl"]);
  const open = Boolean(anchorEl);

  const handleClose = () => setAnchorEl(null);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const hiddenButtonRef = useRef(null);

  return (
    <Box>
      <IconButton
        sx={{
          padding: 0,
          height: { md: "40px", xs: "26px" },
          width: { md: "40px", xs: "27px" },
        }}
        aria-haspopup="true"
        onClick={handleClick}
        aria-expanded={open ? "true" : undefined}
        aria-controls={open ? "account-menu" : undefined}
      >
        <img
          alt={profile["name"]}
          src={avatar}
          style={{
            display: "inline",
            margin: "0 auto",
            height: "100%",
            width: "auto",
          }}
        />
      </IconButton>

      {!isMobile && (
        <Menu
          open={open}
          id="account-menu"
          anchorEl={anchorEl}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              mt: 1,
              boxShadow: 2,
              minWidth: 200,
              borderRadius: "8px",
              overflow: "visible",
              border: "1px solid",
              borderColor: "grey.200",
              display: { md: "block", xs: "none" },
              "& .MuiMenuItem-root:hover": {
                backgroundColor: "grey.200",
              },
              "&:before": {
                top: 0,
                right: 14,
                zIndex: 0,
                width: 10,
                height: 10,
                content: '""',
                display: "block",
                position: "absolute",
                borderTop: "1px solid",
                borderLeft: "1px solid",
                borderColor: "grey.200",
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <Box px={2} pt={1} pb={1}>
            <H6>{profile["name"]}</H6>
          </Box>

          <Divider />
          <Link to={`/c/profile`}>
            <MenuItem>Миний бүртгэл</MenuItem>
          </Link>
          <Link to={`/bonus-card/transfer`}>
            <MenuItem>Бонус карт</MenuItem>
          </Link>
          <Link to={`/c/orders`}>
            <MenuItem>Захиалга</MenuItem>
          </Link>
          <Link to={`/c/addresses`}>
            <MenuItem>Хаяг</MenuItem>
          </Link>
          <Link to={`/c/wishlist`}>
            <MenuItem>Хүслийн жагсаалт</MenuItem>
          </Link>

          <Divider />
          <MenuItem onClick={() => hiddenButtonRef.current.click()}>
            Гарах
          </MenuItem>
          <form action="/logout" method="post" hidden={true}>
            <Button variant="text" type="submit" ref={hiddenButtonRef}>
              гарах
            </Button>
          </form>
        </Menu>
      )}
    </Box>
  );
};

export default AccountPopover;
