import { ChevronRight } from "@mui/icons-material";
import { Box, MenuItem, styled } from "@mui/material";
import { Link } from "@remix-run/react";
import type { FC, ReactElement } from "react";
import BazaarImage from "../BazaarImage";

//styled component
const Wrapper = styled(Box)<{ open: boolean }>(({ open, theme }) => ({
  "& .category-dropdown-link": {
    display: "flex",
    cursor: "pointer",
    whiteSpace: "pre",
    padding: "0px 1rem",
    alignItems: "center",
    transition: "all 250ms ease-in-out",
    "& .title": { flexGrow: 1, paddingLeft: "0.75rem" },
    minHeight: "48px",
  },
  "& .dropdown-icon": {
    transition: "all 250ms ease-in-out",
    transform: `rotate(${open ? "90deg" : "0deg"})`,
  },
}));

// =============================================================
type MobileCategoryMenuItemProps = {
  children?: ReactElement | ReactElement[];
  icon?: any;
  url: string;
  title: string;
  caret?: boolean;
  close?: () => void;
  clickable?: boolean;
};
// =============================================================

const MobileCategoryMenuItem: FC<MobileCategoryMenuItemProps> = (props) => {
  const { url, title, caret, children, icon, close, clickable, ...rest } =
    props;
  return (
    <Wrapper open={true}>
      {!caret || clickable ? (
        <Link to={url} onClick={close}>
          <MenuItem className="category-dropdown-link">
            {icon && <BazaarImage src={icon} sx={{ width: 20, height: 20 }} />}
            <span className="title">{title}</span>
          </MenuItem>
        </Link>
      ) : (
        <MenuItem className="category-dropdown-link">
          {icon && <BazaarImage src={icon} sx={{ width: 20, height: 20 }} />}
          <span className="title">{title}</span>
        </MenuItem>
      )}
      {children}
    </Wrapper>
  );
};

MobileCategoryMenuItem.defaultProps = { caret: true };

export default MobileCategoryMenuItem;
