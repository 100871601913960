import { Box, styled } from "@mui/material";
import type { FC } from "react";
import { Category } from "~/models";
import { useRootLoader } from "~/utils/use-root-loader";
import Laptop from "../icons/Laptop";
import CategoryMenuItem from "./CategoryMenuItem";
import MegaMenu1 from "./mega-menu/MegaMenu1";

// styled component
const Wrapper = styled(Box)<CategoryMenuCardProps>(
  ({ theme, position, open }) => ({
    left: 0,
    zIndex: 98,
    right: "auto",
    borderRadius: 4,
    padding: "0.5rem 0px",
    transformOrigin: "top",
    boxShadow: theme.shadows[2],
    position: position || "unset",
    transition: "all 250ms ease-in-out",
    transform: open ? "scaleY(1)" : "scaleY(0)",
    backgroundColor: theme.palette.background.paper,
    top: position === "absolute" ? "calc(100% + 0.7rem)" : "0.5rem",
  })
);

// ===============================================================
type CategoryMenuCardProps = {
  open?: boolean;
  position?: "absolute" | "relative";
};
// ===============================================================

const CategoryMenuCard: FC<CategoryMenuCardProps> = (props) => {
  const { open, position } = props;
  const { categories } = useRootLoader();

  return (
    <Wrapper open={open} position={position}>
      {categories &&
        categories.map((category: Category) => {
          return (
            category.isIncludedInMenu && (
              <CategoryMenuItem
                key={category.id}
                url={category.url}
                icon={category.icon}
                title={category.name}
                caret={category.caret}
              >
                {category.noc > 0 ? (
                  <MegaMenu1 categories={category.children || []} />
                ) : (
                  <></>
                )}
              </CategoryMenuItem>
            )
          );
        })}
    </Wrapper>
  );
};

CategoryMenuCard.defaultProps = { position: "absolute" };

export default CategoryMenuCard;
