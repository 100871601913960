import { Box, Card, Grid } from "@mui/material";
import { FlexBox } from "~/components/flex-box";
// import LazyImage from "~/components/LazyImage";
import { Link } from "@remix-run/react";
import type { FC } from "react";
import BazaarImage from "~/components/BazaarImage";
import { Category, MenuImage } from "~/models";
import StyledMegaMenu from "./StyledMegaMenu";
import useWindowHeight from "~/hooks/useWindowHeight";

type MegaMenuProps = {
  categories: Category[];
  rightImage?: MenuImage;
  bottomImage?: MenuImage;
};
// =========================================================

const MegaMenu1: FC<MegaMenuProps> = ({
  categories,
  rightImage,
  bottomImage,
}) => {
  const height = useWindowHeight();
  return categories ? (
    <StyledMegaMenu>
      <Card
        elevation={2}
        sx={{
          ml: "1rem",
          minWidth: "760px",
          minHeight: 536,
          maxHeight: 536,
          overflowY: "scroll",
        }}
      >
        <FlexBox px={2.5} py={1.75} alignItems="unset">
          <Box flex="1 1 0">
            <Grid container spacing={4}>
              {categories?.map((category: Category) => (
                <Grid item={true} md={3} key={category.id}>
                  <Link className="title-link" to={category.url}>
                    {category.name}
                  </Link>
                  {category.children?.map((sub: Category) => (
                    <Link className="child-link" to={sub.url} key={sub.id}>
                      {sub.name}
                    </Link>
                  ))}
                </Grid>
              ))}
            </Grid>
          </Box>

          {rightImage && (
            <Box mt={1.5}>
              <Link to={rightImage.to}>
                <BazaarImage src={rightImage.url} width={137} height={318} />
              </Link>
            </Box>
          )}
        </FlexBox>

        {bottomImage && (
          <Link to={bottomImage.to}>
            <Box position="relative" height="170px">
              <BazaarImage src={bottomImage.url} />
            </Box>
          </Link>
        )}
      </Card>
    </StyledMegaMenu>
  ) : null;
};

export default MegaMenu1;
