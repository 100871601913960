import { CssBaseline } from "@mui/material";
import { createTheme, StyledEngineProvider, responsiveFontSizes, ThemeProvider } from "@mui/material/styles";
import { merge } from "merge";
import { FC, ReactNode } from "react";
import customThemeOptions from "./themeOptions";

// =======================================================
type MuiThemeProps = {
  children?: ReactNode;
};
// =======================================================

const MuiTheme: FC<MuiThemeProps> = ({ children }) => {
  const publicRuntimeConfig = { theme: "DEFAULT" };
  const pathname = "/";

  const themeOptions = customThemeOptions(publicRuntimeConfig, pathname);
  let theme = createTheme(merge({}, { ...themeOptions, direction: "ltr" }));
  theme = responsiveFontSizes(theme);

  // theme shadows
  theme.shadows[1] = "0px 1px 3px rgba(3, 0, 71, 0.09)";
  theme.shadows[2] = "0px 4px 16px rgba(43, 52, 69, 0.1)";
  theme.shadows[3] = "0px 8px 45px rgba(3, 0, 71, 0.09)";
  theme.shadows[4] = "0px 0px 28px rgba(3, 0, 71, 0.01)";

  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider>
        <CssBaseline />
        {children}
      </StyledEngineProvider>
    </ThemeProvider>
  );
};

export default MuiTheme;
