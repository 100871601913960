import { Box, Button, Grid, IconButton, Menu, styled } from "@mui/material";
import { type FC, useEffect, useState } from "react";
import { Link } from "@remix-run/react";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import toast from "react-hot-toast";
import CloseIcon from "@mui/icons-material/Close";

// styled components
const Divider = styled(Box)(({ theme }) => ({
  margin: "0",
  border: `1px dashed ${theme.palette.grey[200]}`,
}));

type NotificationsProps = {
  isMobile: boolean;
  notifications: any[];
};

const Notifications: FC<NotificationsProps> = ({ isMobile, notifications }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClose = () => setAnchorEl(null);
  const [lastSeenNotification, setLastSeenNotification] = useState<
    string | null
  >();

  const [notis, setNotis] = useState<any[]>([]);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    localStorage.setItem("last-seen-notification-id", notifications[0]?.id);
    setLastSeenNotification(notifications[0]?.id);
  };

  useEffect(() => {
    const lastNotification =
      localStorage.getItem("last-seen-notification-id") || "hha";

    const readNotificationIds = JSON.parse(
      localStorage.getItem("readNotificationIds") || "[]"
    );

    setNotis(
      notifications.map((notification) => {
        return {
          ...notification,
          isRead: readNotificationIds.includes(notification.id),
        };
      })
    );

    const newestNotification =
      localStorage.getItem("newest-notification-id") || "hha";

    if (notifications && newestNotification !== notifications[0]?.id) {
      setTimeout(() => {
        toast(
          (t) => (
            <Grid container mb={0.5}>
              <IconButton
                style={{
                  height: "18px",
                  width: "18px",
                  right: "5px",
                  top: "5px",
                  position: "absolute",
                }}
                onClick={() => {
                  toast.dismiss(t.id);
                }}
              >
                <CloseIcon
                  style={{
                    height: "18px",
                    width: "18px",
                  }}
                />
              </IconButton>
              <NotificationGrid item={notifications[0]} isRead={true} />
            </Grid>
          ),
          {
            duration: 5000,
          }
        );
      }, 1000);
      localStorage.setItem("newest-notification-id", notifications[0]?.id);
    }
    setLastSeenNotification(lastNotification);
  }, []);

  return (
    <Box
      sx={{
        mr: 2,
        bgcolor: "grey.200",
        borderRadius: "50%",
        position: "relative",
      }}
    >
      {lastSeenNotification && lastSeenNotification !== notifications[0]?.id ? (
        <div
          style={{
            borderRadius: "100%",
            backgroundColor: "red",
            width: "7px",
            height: "7px",
            position: "absolute",
            top: "8px",
            right: "8px",
          }}
        ></div>
      ) : (
        <></>
      )}
      <IconButton
        sx={{
          padding: 1.25,
          height: { md: "45px", xs: "46px" },
          width: { md: "45px", xs: "47px" },
        }}
        aria-haspopup="true"
        onClick={handleClick}
        aria-expanded={open ? "true" : undefined}
        aria-controls={open ? "account-menu" : undefined}
      >
        <NotificationsActiveOutlinedIcon />
      </IconButton>

      {!isMobile && (
        <Menu
          open={open}
          id="account-menu"
          anchorEl={anchorEl}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              mt: 0.2,
              boxShadow: 2,
              minWidth: 200,
              borderRadius: "8px",
              overflow: "scroll",
              overflowX: "hidden",
              border: "1px solid",
              borderColor: "grey.200",
              display: { md: "block", xs: "none" },
              "& .MuiMenuItem-root:hover": {
                backgroundColor: "grey.200",
              },
              "&:before": {
                top: 0,
                right: 14,
                zIndex: 0,
                width: 10,
                height: 10,
                content: '""',
                display: "block",
                position: "absolute",
                borderTop: "1px solid",
                borderLeft: "1px solid",
                borderColor: "grey.200",
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <Box
            px={2}
            pt={1}
            pb={1}
            sx={{ display: "flex", justifyContent: "end" }}
          >
            <Button
              onClick={() => {
                localStorage.setItem(
                  "readNotificationIds",
                  JSON.stringify(notis.map((noti) => noti.id))
                );
                setNotis(
                  notis.map((noti) => {
                    return { ...noti, isRead: true };
                  })
                );
              }}
              sx={{
                border: "none",
                backgroundColor: "white",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              Бүгдийг нь уншсан
            </Button>
          </Box>
          {notis &&
            notis.map((item, idx) => {
              return (
                <Grid container mb={0.5} width={"350px"} key={idx}>
                  <Link
                    to={item.web_url}
                    className="NotificationLink"
                    style={{
                      display: "flex",
                      padding: "5px",
                      width: "100%",
                    }}
                    onClick={() => {
                      const readNotificationIds = JSON.parse(
                        localStorage.getItem("readNotificationIds") || "[]"
                      );
                      readNotificationIds.push(item.id);
                      localStorage.setItem(
                        "readNotificationIds",
                        JSON.stringify(readNotificationIds)
                      );
                      setNotis(
                        notis.map((itemz, idxz) => {
                          if (idxz === idx) {
                            return {
                              ...itemz,
                              isRead: true,
                            };
                          }
                          return itemz;
                        })
                      );
                    }}
                  >
                    <NotificationGrid item={item} isRead={item.isRead} />
                  </Link>
                  <Divider sx={{ color: "gray" }} />
                  <Divider />
                </Grid>
              );
            })}
        </Menu>
      )}
    </Box>
  );
};

export const NotificationGrid = ({ item, isRead }) => {
  return (
    <>
      <Grid
        item
        container
        xs={2}
        sx={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            borderRadius: "100%",
            backgroundColor: "#D5DFF2",
            width: "30px",
            height: "30px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          {item?.type === "noti" ? (
            <img
              src="/assets/images/icons/notificationz.svg"
              alt=""
              width={"20px"}
            />
          ) : (
            <img src="/assets/images/icons/bellz.svg" alt="" width={"20px"} />
          )}
          {!isRead && (
            <div
              style={{
                width: "7px",
                height: "7px",
                borderRadius: "100%",
                backgroundColor: "red",
                position: "absolute",
                top: "0px",
                right: "0px",
              }}
            ></div>
          )}
        </Box>
      </Grid>
      <Grid item xs={10} pl={0.5}>
        <Box
          sx={{
            fontSize: "12px",
            fontWeight: 500,
          }}
        >
          {item?.title}
        </Box>
        <Box sx={{ fontSize: "9px" }}>{item?.content}</Box>
        <Box sx={{ fontSize: "9px", display: "flex", justifyContent: "end" }}>
          {(Math.abs(
            new Date().valueOf() -
              new Date(
                new Date(
                  item?.created_at?.seconds * 1000 +
                    item?.created_at?.nanoseconds / 1000000
                )
              ).valueOf()
          ) /
            (1000 * 60 * 60 * 24)) |
            0}{" "}
          өдрийн өмнө
        </Box>
      </Grid>
    </>
  );
};
export default Notifications;
