import { Box, Divider } from "@mui/material";
import { Form, Link } from "@remix-run/react";
import React, { useState } from "react";
import BazaarButton from "~/components/BazaarButton";
import BazaarImage from "~/components/BazaarImage";
import { FlexBox } from "~/components/flex-box";
import useWindowSize from "~/hooks/useWindowSize";
import { CartInfo, CartProduct } from "~/models";
import CartEmpty from "../header/CartEmpty";
import MiniCartProductCard from "../product-cards/MiniCartProductCard";
import { formatNumber } from "~/utils/utils";

// =========================================================
type MiniCartProps = {
  toggleSidenav: () => void;
  cart: CartInfo;
};
// =========================================================

const MiniCart: React.FC<MiniCartProps> = ({ toggleSidenav, cart }) => {
  const width = useWindowSize();
  return (
    <Box width="380px">
      <Box
        overflow="auto"
        height={`calc(100vh - ${
          width < 1025
            ? "144px - 3.25rem"
            : !!cart.itemsCount
            ? "40px - 3.25rem"
            : "0px"
        })`}
      >
        <FlexBox
          alignItems="center"
          m="0px 20px"
          height="74px"
          color="secondary.main"
        >
          {/* <ShoppingBagOutlined color="inherit" /> */}
          <CartEmpty />
          <Box fontWeight={600} fontSize="16px" ml={1}>
            {formatNumber(cart.itemsCount)} бүтээгдэхүүн
          </Box>
        </FlexBox>

        <Divider />

        {!!!cart.itemsCount && (
          <FlexBox
            alignItems="center"
            flexDirection="column"
            justifyContent="center"
            height="calc(100% - 74px)"
          >
            <BazaarImage
              alt="Empty Bag"
              src="/assets/images/icons/cart-is-empty.png "
              width={"80px"}
            />

            <Box
              component="p"
              mt={2}
              color="grey.600"
              textAlign="center"
              maxWidth="200px"
            >
              Таны сагс хоосон байна
            </Box>
          </FlexBox>
        )}

        {cart.items.map((product: CartProduct) => (
          <MiniCartProductCard
            key={product.id}
            product={product}
            onClose={toggleSidenav}
          />
        ))}
      </Box>

      {!!cart.itemsCount && (
        <Box p={2.5}>
          {/*  FIXME: Хэрэглэгч нэвтэрсэн үед шууд хаяг руу нэвтрээгүй үед нэвтрэх хуудас руу очих */}
          <Link
            to={cart.isAllowCheckout ? "/cart" : "javascript:void(true);"}
            id="checkout-link"
          >
            <BazaarButton
              fullWidth
              color="primary"
              type="submit"
              variant="contained"
              disabled={!cart.isAllowCheckout}
              sx={{ height: "40px" }}
              onClick={toggleSidenav}
            >
              Худалдан авах ({cart.formattedGrandTotal})
            </BazaarButton>
          </Link>

          {/* <Link
            to={cart.isAllowCheckout ? "/cart" : "javascript:void(true);"}
            id="checkout-link"
          >
            <BazaarButton
              type="submit"
              disabled={!cart.isAllowCheckout}
              fullWidth
              color="primary"
              variant="outlined"
              sx={{ height: 40 }}
              onClick={toggleSidenav}
            >
              Сагс руу очих
            </BazaarButton>
          </Link> */}
        </Box>
      )}
    </Box>
  );
};

MiniCart.defaultProps = {
  toggleSidenav: () => {},
};

export default MiniCart;
