import debounce from "lodash/debounce";
import { useEffect, useState } from "react";

const useWindowHeight = () => {
  const [height, setHeight] = useState(0);

  const windowListener = debounce(() => {
    if (window) setHeight(window.innerHeight);
  }, 250);

  useEffect(() => {
    if (window) {
      setHeight(window.innerHeight);
      window.addEventListener("resize", windowListener);
    }
    return () => {
      windowListener.cancel();
      window && window.removeEventListener("resize", windowListener);
    };
  }, [windowListener]);

  return height;
};

export default useWindowHeight;