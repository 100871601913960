import { Box, Container, Grid, styled } from "@mui/material";
import { Link, useLocation } from "@remix-run/react";
import { type FC } from "react";
import BazaarIconButton from "~/components/BazaarIconButton";
import BazaarImage from "~/components/BazaarImage";
import { FlexBox } from "~/components/flex-box";
import appIcons from "~/components/icons";

// styled component
const StyledLink = styled(Link)(({ theme }) => ({
  display: "block",
  borderRadius: 4,
  cursor: "pointer",
  position: "relative",
  padding: "0.3rem 0rem",
  color: "white",
  "&:hover": { color: theme.palette.grey[100] },
}));

const Footer: FC = () => {
  const FacebookIcon = appIcons["Facebook"];
  const InstagramIcon = appIcons["Instagram"];
  const { pathname } = useLocation();
  return (
    <footer>
      <Box bgcolor={"#2d68c4"} sx={{ pt: { xs: 2.5, md: 9 } }}>
        <Container sx={{ color: "white" }}>
          <Box pb={10} overflow="hidden">
            <Grid container spacing={3}>
              <Grid item lg={2} md={6} sm={6} xs={6}>
                <Box
                  sx={{ fontSize: { md: "18px", xs: "16px", } }}
                  fontWeight="600"
                  mb={1.5}
                  lineHeight="1"
                  color="white"
                >
                  Бидний тухай
                </Box>

                <div>
                  {aboutLinks.map((item, ind) =>
                    item.isExternal ? (
                      <StyledLink
                        key={ind}
                        to={`${item.link}`}
                        target="_blank"
                        sx={{ fontSize: { xs: "12px", md: "14px" }, fontWeight: "500" }}
                      >
                        {item.name}
                      </StyledLink>
                    ) : (
                      <StyledLink
                        key={ind}
                        to={`/${item.link}`}
                        sx={{ fontSize: { xs: "12px", md: "14px" }, fontWeight: "500" }}
                      >
                        {item.name}
                      </StyledLink>
                    )
                  )}
                </div>
              </Grid>

              <Grid item lg={2} md={6} sm={6} xs={6}>
                <Box
                  sx={{ fontSize: { md: "18px", xs: "16px" } }}
                  fontWeight="600"
                  mb={1.5}
                  lineHeight="1"
                  color="white"
                >
                  Тусламж
                </Box>

                <div>
                  {customerCareLinks.map((item, ind) => (
                    <StyledLink
                      key={ind}
                      to={`/${item.link}`}
                      sx={{ fontSize: { xs: "12px", md: "14px" }, fontWeight: "500" }}

                    >
                      {item.name}
                    </StyledLink>
                  ))}
                </div>
              </Grid>
              <Grid item lg={3} md={6} sm={6} xs={6}>
                <Box
                  sx={{ fontSize: { md: "18px", xs: "16px" } }}
                  fontWeight="600"
                  mb={1.5}
                  lineHeight="1"
                  color="white"
                >
                  Хаяг
                </Box>
                <Box
                  py={0.6}
                  color="white"
                  sx={{
                    fontSize: { xs: "12px", md: "14px" },
                    lineHeight: "2.1",
                    marginTop: "-2px",
                  }}
                  fontWeight={500}
                >
                  Юнайтед оффис, Хан-Уул дүүрэг, Чингисийн өргөн чөлөө,
                  Улаанбаатар 17042, Монгол Улс, 210136, Ш/Ч-2316
                </Box>
              </Grid>

              <Grid item lg={2} md={6} sm={6} xs={6}>
                <Box
                  sx={{ fontSize: { md: "18px", xs: "16px" } }}
                  fontWeight="600"
                  mb={1.5}
                  lineHeight="1"
                  color="white"
                >
                  Холбоо барих
                </Box>
                <Box
                  py={0.6}
                  color="white"
                  sx={{ fontSize: { xs: "12px", md: "14px" } }}
                  fontWeight={500}
                >
                  И-мэйл: eshop@nomin.net
                </Box>
                <Box
                  py={0.6}
                  color="white"
                  sx={{ fontSize: { xs: "12px", md: "14px" } }}
                  fontWeight={500}
                >
                  Утас: (+976) 1800-2888
                </Box>

                <FlexBox className="flex" mx={-0.625}>
                  <a
                    href="https://www.facebook.com/www.nomin.mn"
                    target="_blank"
                    rel="noreferrer noopenner"
                    key="facebook"
                  >
                    <BazaarIconButton
                      m={0.5}
                      bgcolor="rgba(0,0,0,0.2)"
                      fontSize="12px"
                      padding="10px"
                    >
                      <FacebookIcon fontSize="inherit" />
                    </BazaarIconButton>
                  </a>
                  <a
                    href="https://www.instagram.com/nominmn.official/"
                    target="_blank"
                    rel="noreferrer noopenner"
                    key="instagram"
                  >
                    <BazaarIconButton
                      m={0.5}
                      bgcolor="rgba(0,0,0,0.2)"
                      fontSize="12px"
                      padding="10px"
                    >
                      <InstagramIcon fontSize="inherit" />
                    </BazaarIconButton>
                  </a>
                </FlexBox>
              </Grid>
              <Grid item lg={3} md={6} sm={6} xs={12}>
                <Box
                  sx={{ fontSize: { md: "18px", xs: "16px" } }}
                  fontWeight="600"
                  mb={1.5}
                  lineHeight="1"
                  color="white"
                >
                  Апп татах
                </Box>
                <Grid container>
                  <Grid item xs={4}>
                    <BazaarImage
                      src="/assets/images/app-qrcode.svg"
                      width={90}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <a
                      href="https://play.google.com/store/apps/details?id=mn.nomin.eshop&hl=en&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                      target="_blank"
                      style={{
                        display: "inline-block",
                        overflow: "hidden",
                        width: "131px",
                        height: "37px",
                        marginLeft: "9px",
                      }}
                    >
                      <img
                        alt="Get it on Google Play"
                        src="/assets/images/logos/download-from-playstore.svg"
                        style={{
                          width: "131px",
                          height: "37px",
                        }}
                      />
                    </a>
                    <a
                      href="https://apps.apple.com/us/app/nomin-eshop/id6444114008?itsct=apps_box_badge&amp;itscg=30200"
                      target="_blank"
                      style={{
                        display: "inline-block",
                        overflow: "hidden",
                        width: "131px",
                        height: "37px",
                        marginLeft: "9px",
                        marginTop: "9px",
                      }}
                    >
                      <img
                        src="/assets/images/logos/download-from-appstore.svg"
                        alt="Download on the App Store"
                        style={{
                          width: "131px",
                          height: "37px",
                        }}
                      />
                    </a>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </footer>
  );
};

const aboutLinks = [
  {
    name: "Бидний тухай",
    link: "about-us",
    isExternal: true,
  },
  {
    name: "Номингийн 5 баталгаа",
    link: "https://www.nomin.co/about/why-choose-us",
    isExternal: true,
  },
  {
    name: "Нээлттэй ажлын байр",
    link: "https://erp.nomin.mn/my/open_jobs",
    isExternal: true,
  },
];

const customerCareLinks = [
  {
    name: "Захиалгын төлөв шалгах",
    link: "ot",
  },
  {
    name: "Үйлчилгээний нөхцөл",
    link: "tos",
  },
  {
    name: "Түгээмэл асуулт, хариулт",
    link: "faq",
  },
];

export default Footer;
