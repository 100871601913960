import { cssBundleHref } from "@remix-run/css-bundle";
import {
  LinksFunction,
  LoaderFunction,
  LoaderFunctionArgs,
  json } from
"@remix-run/node";
import ReactGA from "react-ga4";
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useLoaderData,
  useLocation,
  useRouteError } from
"@remix-run/react";
import commerce from "~/servers/commerce.server";
import styles from "~/styles/index.css";
import ErrorLayout from "./components/layouts/ErrorLayout";
import ShopLayout from "./components/layouts/ShopLayout";
import InternalServerErrorPage from "./components/pages/InternalServerError";
import NotFoundPage from "./components/pages/NotFound";
import useWindowSize from "./hooks/useWindowSize";
import { getSession } from "./servers/session.server";
import { redirectToForceLogout } from "./utils/request.server";
import {
  getNotifications,
  Notification } from
"./route-containers/notification.server";
import redis from "~/servers/redis.server";
import { ClientOnly } from "remix-utils/client-only";

import { createHead } from "remix-island";
import { urqlSmart } from "./servers/urql.server";
import {
  CACHE_EXPIRES_IN_EIGHT_HOURS,
  CACHE_NOTIFICATIONS } from
"./utils/constants";
import { useEffect } from "react";
import Trackers from "./components/Trackers";
import toast, { Toaster } from "react-hot-toast";
import { getToast } from "remix-toast";

export function meta() {
  return [
  { charset: "utf-8" },
  { viewport: "width=device-width, initial-scale=1" },
  { title: "Nomin E-Shop | Хүний хэрэгцээ цөм багтсан" },
  { "emotion-insertion-point": "emotion-insertion-point" }];

}

export const Head = createHead(() =>
<>
    <meta
    name="viewport"
    content="width=device-width,initial-scale=1.0,maximum-scale=1.0,user-scalable=no" />

    <meta
    name="facebook-domain-verification"
    content="yq01dukhk67bg31hhae5f2ffgu9t6c" />

    <Meta />
    <Links />
    <link
    rel="stylesheet"
    href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />

    <meta name="emotion-insertion-point" content="emotion-insertion-point" />
    <meta name="cf-2fa-verify" content="437aaf2ac726c90" />
    <meta
    property="og:image:url"
    content="https://cdn5.nomin.mn/media/wysiwyg/nomin5handslong.png" />

  </>
);

const devMode =
typeof process !== "undefined" && process.env.NODE_ENV === "development";

export type RootLoaderData = {
  categories: Awaited<ReturnType<typeof commerce.fetchMenuCategories>>;
  cart: Awaited<ReturnType<any>>;
  banners: Awaited<ReturnType<typeof commerce.fetchBanners>>;
  isCustomerLoggedIn: Awaited<boolean>;
  isBonusApplied: Awaited<boolean>;
  notifications: Awaited<Notification[]>;
  profile?: any;
  toastMessage?: any;
};

export const loader: LoaderFunction = async ({
  request,
  params
}: LoaderFunctionArgs) => {
  let session = await getSession(request, params);
  const isCustomerLoggedIn = session.isCustomerLoggedIn();
  const { toast, headers } = await getToast(request);

  let notifications: Notification[] = [];
  if (await redis?.exists(CACHE_NOTIFICATIONS)) {
    notifications = JSON.parse((await redis?.get(CACHE_NOTIFICATIONS)) ?? "[]");
  } else {
    notifications = await getNotifications();
    await redis?.set(
      CACHE_NOTIFICATIONS,
      JSON.stringify(notifications),
      "EX",
      CACHE_EXPIRES_IN_EIGHT_HOURS
    );
  }
  // const notifications = await getNotifications();
  // const flashMessage = session.getMessage();
  const cartId = session.getCartId();
  const sdk = urqlSmart(session);
  console.log(`------ cartID: ${cartId}`);
  console.log(`------ access token: ${session.getCustomerAccessToken()}`);
  console.log(`------ customer logged in?: ${session.isCustomerLoggedIn()}`);
  console.log(`------ is bonus applied?: ${session.isBonusApplied()}`);
  console.log(`------ customer wishlist id: ${session.getWishlistId()}`);
  const categories = await commerce.fetchMenuCategories(sdk);
  try {
    const data: RootLoaderData = {
      categories: categories,
      cart: await commerce.getCartInfo(session),
      banners: await commerce.fetchBanners(),
      notifications: notifications,
      // searchDefaultResults: await commerce.fetchSearchDefaultResults(),
      isCustomerLoggedIn: isCustomerLoggedIn,
      isBonusApplied: session.isBonusApplied(),
      toastMessage: toast
    };

    if (isCustomerLoggedIn) {
      const profile = session.getCustomerData();
      data.profile = {
        name: profile.customerName,
        avatarUrl: profile.avatarUrl
      };
    }
    return json(data, { headers });
  } catch (error) {
    console.log("----- GLOBAL ERROR OCCURED -----");
    console.log(error);
    return redirectToForceLogout(session);
  }
};

export const links: LinksFunction = () => [
...(cssBundleHref ? [{ rel: "stylesheet", href: cssBundleHref }] : []),
{ rel: "stylesheet", href: styles }];


function App() {
  const {
    categories,
    toastMessage
  } = useLoaderData<RootLoaderData>();

  //analytics tag
  useEffect(() => {
    ReactGA.initialize("G-Y9TYGSBSKM");
  }, []);

  useEffect(() => {
    if (toastMessage) {
      if (toastMessage.type === "success") {
        toast.success(toastMessage.message, {
          style: {
            borderRadius: "10px",
            background: "#fff",
            color: "#333"
          }
        });
      } else {
        toast.error(toastMessage.message, {
          style: {
            borderRadius: "10px",
            background: "#fff",
            color: "#333"
          }
        });
      }
    }
  }, [toastMessage]);

  return (
    <>
      <Head />
      <ShopLayout topbarBgColor={"#2d68c4"}>
        <Outlet context={{ categories }} />
      </ShopLayout>
      <ScrollRestoration />
      <Scripts />
      <Toaster position="top-right" />
      <ClientOnly fallback={<></>}>{() => <Trackers />}</ClientOnly>
      {devMode && <LiveReload />}
    </>);

}

export default App;

export function ErrorBoundary() {
  const error = useRouteError();
  const width = useWindowSize();
  console.log("----- ERROR BOUNDARY:");
  console.log(JSON.stringify(error, null, 2));

  // when true, this is what used to go to `CatchBoundary`
  // INFO: 404 page
  if (isRouteErrorResponse(error)) {
    return (
      <div>
        <ErrorLayout topbarBgColor={"#2d68c4"} showTopbar={width > 1024}>
          <NotFoundPage />
        </ErrorLayout>
      </div>);

  }

  // Don't forget to typecheck with your own logic.
  // Any value can be thrown, not just errors!
  let errorMessage = "Unknown error";
  if (isDefinitelyAnError(error)) {
    errorMessage = (error as Error)?.message || "error occurred";
  }

  return (
    <div>
      <ErrorLayout topbarBgColor={"#2d68c4"}>
        {/* <ClearAllCachePage /> */}
        <InternalServerErrorPage />
      </ErrorLayout>
    </div>);

}

function isDefinitelyAnError(error: unknown) {
  return true;
}