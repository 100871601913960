import { Box, Container } from "@mui/material";
import { FC } from "react";
import BazaarButton from "../BazaarButton";
import { Link } from "@remix-run/react";

const InternalServerErrorPage: FC = () => {
  return (
    <Container style={{ paddingTop: "100px" }}>
      <div style={{ textAlign: "center" }}>
        <img src="/assets/images/500.png" alt="" width={"200px"} />
      </div>
      <Box
        textAlign={"center"}
        marginTop={"30px"}
        color={"#333"}
        fontWeight={"500"}
        sx={{ fontSize: { xs: "32px", md: "36px" } }}
      >
        УУЧЛААРАЙ
      </Box>
      <Box
        textAlign={"center"}
        marginTop={"20px"}
        color={"#000000"}
        sx={{
          fontSize: { xs: "18px", md: "24px" },
          padding: { xs: "0 15px", md: "0" },
        }}
        fontWeight={"400"}
      >
        Системд алдаа гарсан тул та дахин оролдоно уу!
      </Box>
      <div style={{ textAlign: "center", marginBottom: "100px" }}>
        <BazaarButton
          variant="contained"
          disableElevation
          onClick={() => window.history.back()}
          sx={{
            color: "#fff",
            marginTop: "30px",
            marginRight: { xs: "0px", md: "10px" },
            backgroundColor: "#8bc34a",
            px: "1rem",
            height: "100%",
            width: "300px",
            borderRadius: "10px",
            fontSize: "18px",
            border: "1px #8bc34a solid",
            ":hover": {
              backgroundColor: "#ffffff",
              color: "#2f6db8",
              border: "1px  #2f6db8 solid",
            },
          }}
          type="submit"
        >
          Өмнөх хуудас руу буцах
        </BazaarButton>

        <Link to="/">
          <BazaarButton
            color="secondary"
            variant="contained"
            disableElevation
            sx={{
              marginTop: "30px",
              backgroundColor: "#2f6db8",
              px: "1rem",
              height: "100%",
              width: "300px",
              borderRadius: "10px",
              fontSize: "18px",
              border: "1px  #2f6db8 solid",
              ":hover": {
                backgroundColor: "#ffffff",
                color: "#2f6db8",
                border: "1px  #2f6db8 solid",
              },
            }}
            type="submit"
          >
            Нүүр хуудас руу буцах
          </BazaarButton>
        </Link>
      </div>
    </Container>
  );
};

export default InternalServerErrorPage;
