export default function Trackers() {
  return <>
    {/* Google Analytics */}
    {/* <script async src="https://www.googletagmanager.com/gtag/js?id=G-Y9TYGSBSKM"></script>
    <script dangerouslySetInnerHTML={{
      __html: `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'G-Y9TYGSBSKM');
      `
    }} /> */}

    {/* Start of HubSpot Embed Code */}
    <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/43598382.js"></script>
    {/* End of HubSpot Embed Code */}

    {/* ManyChat */}
    <script src="//widget.manychat.com/793620_cf742.js" defer />
    <script src="https://mccdn.me/assets/js/widget.js" defer />

    {/* Facebook Pixel */}
    <script dangerouslySetInnerHTML={{
      __html: `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '1932580003779103');
        fbq('track', 'PageView');
      `
    }} />

    {/* Brevo */}
    <script dangerouslySetInnerHTML={{
      __html: `
      (function() {
          window.sib = {
              equeue: [],
              client_key: "59ws3us4ucit773esyat1sx7"
          };
          /* OPTIONAL: email for identify request*/
          // window.sib.email_id = 'example@domain.com';
          window.sendinblue = {};
          for (var j = ['track', 'identify', 'trackLink', 'page'], i = 0; i < j.length; i++) {
          (function(k) {
              window.sendinblue[k] = function() {
                  var arg = Array.prototype.slice.call(arguments);
                  (window.sib[k] || function() {
                          var t = {};
                          t[k] = arg;
                          window.sib.equeue.push(t);
                      })(arg[0], arg[1], arg[2], arg[3]);
                  };
              })(j[i]);
          }
          var n = document.createElement("script"),
              i = document.getElementsByTagName("script")[0];
          n.type = "text/javascript", n.id = "sendinblue-js", n.async = !0, n.src = "https://sibautomation.com/sa.js?key=" + window.sib.client_key, i.parentNode.insertBefore(n, i), window.sendinblue.page();
      })();
      `
    }} />
  </>;
}
